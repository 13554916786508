import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Heading from '../components/heading';
import Text from '../components/text';
import './404.scss';

// markup
const NotFoundPage = () => {
    return (
        <Layout className="not-found-page">
            <title>Not found</title>
            <Heading level={1}>Page not found</Heading>
            <Text size="large">
                <b>Sorry about that,</b> we couldn't find the page you were
                looking for.
            </Text>
            <Text size="large">
                <Link className="home-link" to="/">
                    Go to home
                </Link>
            </Text>
        </Layout>
    );
};

export default NotFoundPage;
